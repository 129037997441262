import DLink from "@/components/utilities/DynamicLink";
import { processGalleryContent, processGroups, processImages } from "@/scripts/ProcessImages";

const EXPLORATIONS_IMGS = processImages(
  {
    modern_room: {
      name: "modern_room",
      src: "/assets/images/explorations/3d_design/modern_room.jpg",
      alt: "Photorealistic 3d rendering of a modern room",
      width: 2560,
      height: 1440,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      title: "Modern Room",
      //   description: "",
      //   study: "",
      //   group: "",
      //   index: 0,
    },
    appa: {
      name: "appa",
      src: "/assets/images/explorations/3d_design/appa.png",
      alt: "Low poly rendering of Appa from Avatar: The Last Airbender",
      width: 2000,
      height: 2000,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      title: "Appa",
      //   description: "",
      //   study: "",
      //   group: "",
      //   index: 0,
    },
    cinema: {
      name: "cinema",
      src: "/assets/images/explorations/3d_design/cinema.png",
      alt: "Photorealistic 3d rendering of the outside of a cinema",
      width: 1303,
      height: 1512,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop"],
      title: "Cinema",
      //   description: "",
      //   study: "",
      //   group: "",
      //   index: 0,
    },
    finn: {
      name: "finn",
      src: "/assets/images/explorations/3d_design/finn.png",
      alt: "Low poly rendering of Finn from Adventure Time",
      width: 1080,
      height: 1080,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      title: "Finn",
      //   description: "",
      //   study: "",
      //   group: "",
      //   index: 0,
    },
    // headphones: {
    //   name: "headphones",
    //   src: "/assets/images/explorations/3d_design/headphones.png",
    //   alt: "A photorealistic rendering of a pair of headphones hanging on a wall",
    //   width: 2000,
    //   height: 2000,
    //   disciplines: ["3D Design"],
    //   tools: ["Blender", "Photoshop"],
    //   title: "Headphones",
    // },
    pops: {
      name: "pops",
      src: "/assets/images/explorations/3d_design/pops.png",
      alt: "Low poly rendering of Pops from Regular Show",
      width: 1920,
      height: 1080,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      title: "Pops",
      //   description: "",
      //   study: "",
      //   group: "",
      //   index: 0,
    },
    submarine: {
      name: "submarine",
      src: "/assets/images/explorations/3d_design/submarine.png",
      alt: "A photorealistic of the interior of a submarine",
      width: 1800,
      height: 1100,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      title: "Submarine",
      //   description: "",
      //   study: "",
      //   group: "",
      //   index: 0,
    },
    to_alleyway_thumbnail: {
      name: "to_alleyway_thumbnail",
      src: "/assets/images/explorations/3d_design/to_alleyway/to_alleyway_thumbnail.png",
      alt: "Thumbnail for a video of a garage door opening to reveal a pair of eyes underneath",
      width: 1920,
      height: 1200,
      disciplines: ["3D Design", "Motion Graphics"],
      tools: ["Blender", "Meshroom", "Photoshop"],
      description: "Photogrammetry study of a grafitti covered alleyway in downtown Toronto.",
      group: "to_alleyway",
      hidden: true,
      thumbnail: true,
      project: "Grafitti Alley",
    },
    to_alleyway_video: {
      name: "to_alleyway_video",
      src: "/assets/images/explorations/3d_design/to_alleyway/to_alleyway_video.mp4",
      alt: "An animated video of a garage door opening to reveal a pair of eyes underneath",
      width: 1920,
      height: 1200,
      disciplines: ["3D Design", "Motion Graphics"],
      tools: ["Blender", "Meshroom", "Photoshop"],
      description: "Photogrammetry study of a grafitti covered alleyway in downtown Toronto.",
      group: "to_alleyway",
      project: "Grafitti Alley",
    },

    jabbar_and_co_foundation_01: {
      name: "jabbar_and_co_foundation_01",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_foundation_01.png",
      alt: "A bottle of makeup foundation in a pinkish shade",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Bottle #1",
      description:
        "3D rendering of a bottle of foundation created for Jabbar & Co. as part of a project to create product renderings for their online store. This bottle is one of 110 different shades of foundation that were included in the project scope.",
      //   study: "",
      group: "foundation_bottles",
      project: "Jabbar & Co. 110 Shades Line",
      //   index: 0,
    },
    jabbar_and_co_foundation_02: {
      name: "jabbar_and_co_foundation_02",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_foundation_02.png",
      alt: "A bottle of makeup foundation in a beige shade",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Bottle #2",
      description:
        "3D rendering of a bottle of foundation created for Jabbar & Co. as part of a project to create product renderings for their online store. This bottle is one of 110 different shades of foundation that were included in the project scope.",
      //   study: "",
      group: "foundation_bottles",
      project: "Jabbar & Co. 110 Shades Line",
      //   index: 0,
    },
    jabbar_and_co_foundation_03: {
      name: "jabbar_and_co_foundation_03",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_foundation_03.png",
      alt: "A bottle of makeup foundation in a dark brown shade",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Bottle #3",
      description:
        "3D rendering of a bottle of foundation created for Jabbar & Co. as part of a project to create product renderings for their online store. This bottle is one of 110 different shades of foundation that were included in the project scope.",
      //   study: "",
      group: "foundation_bottles",
      project: "Jabbar & Co. 110 Shades Line",
      //   index: 0,
    },
    jabbar_and_co_foundation_video_01: {
      name: "limitations_bottle",
      src: "/assets/images/made/limitations/limitations_bottle.mp4",
      alt: "A 3D rendering of a foundation makeup bottle product shot.  The bottle’s label reads ‘Jabbar & Co.’. The bottle cycles through a series of different skin tone colors to demonstrate how the previously described system was scaleable and was able to be reused in a later project of mine.",
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Random Bottles",
      description:
        "3D rendering of a bottle of foundation created for Jabbar & Co. as part of a project to create product renderings for their online store. This bottle is one of 110 different shades of foundation that were included in the project scope.",
      width: 1200,
      height: 1200,
      group: "foundation_bottles",
      project: "Jabbar & Co. 110 Shades Line",
    },
    jabbar_and_co_products_01: {
      name: "jabbar_and_co_products_01",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_01.png",
      alt: "A product rendering of a makeup brush",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Makeup Brush",
      description:
        "3D product rendering of a makeup brush created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_02: {
      name: "jabbar_and_co_products_02",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_02.png",
      alt: "A product rendering of a tube of cleanser",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Cleanse",
      description:
        "3D product rendering of a tube of Botanical Cleansing Lotion created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_03: {
      name: "jabbar_and_co_products_03",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_03.png",
      alt: "A product rendering of a bottle of botanical firming toner",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Tone",
      description:
        "3D product rendering of Botanical Firming Toner created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_04: {
      name: "jabbar_and_co_products_04",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_04.png",
      alt: "A product rendering of firming face and eye serum",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Firm",
      description:
        "3D product rendering of Firming Face And Eye Serum created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_05: {
      name: "jabbar_and_co_products_05",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_05.png",
      alt: "A product rendering of a tube of hydrating mango face cream",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Hydrate",
      description:
        "3D product rendering of Hydrating Mango Face Cream created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_06: {
      name: "jabbar_and_co_products_06",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_06.png",
      alt: "A product rendering of a spray bottle of setting spray",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Setting Spray",
      description:
        "3D product rendering of Setting Spray created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_07: {
      name: "jabbar_and_co_products_07",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_07.png",
      alt: "A product rendering of an angular black glass jar of anti-aging face cream",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Anti-Aging",
      description:
        "3D product rendering of Anti-Aging Cream created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_08: {
      name: "jabbar_and_co_products_08",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_08.png",
      alt: "A product rendering of an angular black glass jar of anti-aging face cream with the lid removed",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Anti-Aging",
      description:
        "3D product rendering of Anti-Aging Cream created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      thumbnail: true,

      //   index: 0,
    },
    jabbar_and_co_products_09: {
      name: "jabbar_and_co_products_09",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_09.png",
      alt: "A product rendering of an angular black glass jar of hyaluronic moisturizer",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Intense",
      description:
        "3D product rendering of Hyaluronic Moisturizer created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",

      //   index: 0,
    },
    jabbar_and_co_products_10: {
      name: "jabbar_and_co_products_10",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_10.png",
      alt: "A product rendering of an angular black glass jar of hyaluronic moisturizer with the lid off",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Intense",
      description:
        "3D product rendering of Hyaluronic Moisturizer created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",

      //   index: 0,
    },
    jabbar_and_co_products_11: {
      name: "jabbar_and_co_products_11",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_11.png",
      alt: "A product rendering of a buki brush",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Baby Buki",
      description: "3D product rendering of a Buki Brush created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_12: {
      name: "jabbar_and_co_products_12",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_12.png",
      alt: "A product rendering of a black blotting powder container that is closed",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Blotting Powder",
      description:
        "3D product rendering of Blotting Powder created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_13: {
      name: "jabbar_and_co_products_13",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_13.png",
      alt: "A product rendering of a black blotting powder container that is open",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Blotting Powder",
      description:
        "3D product rendering of Blotting Powder created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_14: {
      name: "jabbar_and_co_products_14",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_14.png",
      alt: "A product rendering of a small glass jar of loose setting powder",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Setting Powder",
      description:
        "3D product rendering of Setting Powder created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",

      //   index: 0,
    },
    jabbar_and_co_products_15: {
      name: "jabbar_and_co_products_15",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_15.png",
      alt: "A product rendering of a small glass jar of loose setting powder with the lid removed",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Setting Powder",
      description:
        "3D product rendering of Setting Powder created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",
      //   index: 0,
    },
    jabbar_and_co_products_16: {
      name: "jabbar_and_co_products_16",
      src: "/assets/images/explorations/3d_design/jabbar_and_co/jabbar_and_co_products_16.png",
      alt: "A product rendering of a black, cream-dispensing pump bottle of ‘Camera Ready Primer’",
      width: 1024,
      height: 1270,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Adobe Dimension", "Adobe Illustrator"],
      title: "Primer",
      description:
        "3D product rendering of ‘Camera Ready Primer’ created for Jabbar & Co. as part of a project to create images for their online storefront.",
      //   study: "",
      group: "jabbar_and_co_products",
      project: "Jabbar & Co. Product Line",

      //   index: 0,
    },
    made_shirts_01: {
      name: "made_shirts_01",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_01.png",
      alt: "A plaid shirt with no contrast fabric, french cuff, and mini-wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Random Dress Shirt #1",
      description:
        "One of many 3D renderings created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_02: {
      name: "made_shirts_02",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_02.png",
      alt: "A dark grey shirt with black and white plaid contrast fabric, regular cuff, and mini-wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_03: {
      name: "made_shirts_03",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_03.png",
      alt: "A dark blue and green plaid shirt with plain dark blue contrast fabric, french cuff, and wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_04: {
      name: "made_shirts_04",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_04.png",
      alt: "A dark green shirt with blue paisley contrast fabric, french cuff, and band collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_05: {
      name: "made_shirts_05",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_05.png",
      alt: "A light grey shirt with black and white spotted contrast fabric, french cuff, and wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_06: {
      name: "made_shirts_06",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_06.png",
      alt: "A light blue shirt with intricate blue and white contrast fabric, french cuff, and wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_07: {
      name: "made_shirts_07",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_07.png",
      alt: "A white shirt with no contrast fabric, french cuff, and mini-wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    made_shirts_08: {
      name: "made_shirts_08",
      src: "/assets/images/explorations/3d_design/made_shirts/made_shirts_08.png",
      alt: "A grey shirt with light blue contrast fabric, french cuff, and wide collar",
      width: 1200,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender", "Photoshop", "Python"],
      title: "Randomized Dress Shirt",
      description:
        "3D rendering of one of the completed dress shirts created for MADE Clothing Co.’s online dress shirt configurator. Each shirt piece was rendered in 40+ real-world fabrics. The final outcome was isolated images of shirt bodies, collars, cuffs, and plackets, that can be easily stacked to create a variety of different shirts, like the one shown here.",
      study: "MADE",
      group: "made_shirts",
      project: "Made Clothing Co. Products",
      //   index: 0,
    },
    dome_01: {
      name: "dome_01",
      src: "/assets/images/explorations/3d_design/nft_dome/dome_01.png",
      alt: "A 3d rendering of an intricate abstract, black and blue metalic futuristic building on a tropical island beach surrounded by palm trees",
      width: 1800,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      //   title: "",
      description: "A promotional / concept art 3D rendering created for Virtual Pangea.",
      //   study: "",
      group: "nft_dome",
      project: "The Dome",
      //   index: 0,
    },
    dome_02: {
      name: "dome_02",
      src: "/assets/images/explorations/3d_design/nft_dome/dome_02.png",
      alt: "A 3d rendering of an intricate abstract, black and blue metalic futuristic building from a higher angle than the first image",
      width: 1800,
      height: 1200,
      disciplines: ["3D Design"],
      tools: ["Blender"],
      //   title: "",
      description: "A promotional / concept art 3D rendering created for Virtual Pangea.",
      //   study: "",
      group: "nft_dome",
      project: "The Dome",
      //   index: 0,
    },
    obsessive_seas_render_01: {
      name: "obsessive_seas_render_01",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_render_01.png",
      alt: "A 3D rendering of the interior of a small art gallery space with an abstract object casting shadows in the back corner, a projection of ocean waves on the wall, and 2 groups of framed artworks on the wall across from each other",
      width: 1920,
      height: 1400,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Gallery Space Wide Shot",
      description: [
        `Working with COPE NYC director, and gallery curator Vida Sabbaghi, I designed the layout of the "Obsessive Seas" exhibition at Serendipity Artspace in Taipei. Using 3D and experiential design skills, I created a scale-accurate model of the digital gallery space and its artworks.`,
        `I composed the interior with a sparring usage of flair to cohere the limited number of artworks. These touches included lighting, shadows, accent walls, and projected footage. They filled the space and crafted a thematically consistent atmosphere.`,
        `After completion, I added annotations to the final renders with instructions and measurements for gallery staff to set up the exhibit.`,
        `The featured artists were Barbara Ellmann, Carla Goldberg, and Jacques Jarrige.`,
      ],
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 0,
    },
    obsessive_seas_render_05: {
      name: "obsessive_seas_render_05",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_render_05.png",
      alt: "A wider rendering of the ocean waves being projected on the wall in the Obsessive Seas art gallery",
      width: 1920,
      height: 1400,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Rendered Wide Shot of Ocean Wave Projection",
      // description: "A wider 3D shot of the ocean wave projection within the exhibition space.",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 1,
    },
    obsessive_seas_gallery_01: {
      name: "obsessive_seas_gallery_01",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_gallery_01.jpg",
      alt: "A real world photograph of a metal podium standing accross the room from a projection of ocean waves, and a blue wall with information about the art gallery exhibit",
      width: 1776,
      height: 1184,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Real-World ‘Obsessive Seas’ Exhibition",
      // description: "A real-world image of the ‘Obsessive Seas’ gallery at the Serendipity Art Space in Taipei, Taiwan.",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 2,
    },
    obsessive_seas_render_03: {
      name: "obsessive_seas_render_03",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_render_03.png",
      alt: "A tightly focused 3D rendering of the latter of the two groups of framed artworks on the wall across from each other in the Obsessive Seas art gallery",
      width: 1920,
      height: 1400,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Positioning of Barbara Ellmann’s Work in Digital Gallery",
      description: "",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 3,
    },
    obsessive_seas_measurements_02: {
      name: "obsessive_seas_measurements_02",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_measurements_02.png",
      alt: "A tightly focused 3D rendering of the latter of the two groups of framed artworks on the wall across from each other in the Obsessive Seas art gallery, with annotated measurements",
      width: 1920,
      height: 1401,
      title: "Annotated Render of Barbara Ellmann’s Work in Digital Gallery",
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 4,
    },
    obsessive_seas_gallery_02: {
      name: "obsessive_seas_gallery_02",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_gallery_02.jpg",
      alt: "A real world photograph of a woman standing in front of a series of acrylic and glass works of art",
      width: 4160,
      height: 6240,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Exhibition Attendee Admiring The Work of Carla Goldberg",
      // description: "A real-world image of an attendee at appreciating the artwork in the ‘Obsessive Seas’ gallery at the Serendipity Art Space in Taipei, Taiwan.",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 5,
    },
    obsessive_seas_render_04: {
      name: "obsessive_seas_render_04",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_render_04.png",
      alt: "A close rendering of the blue wall with information about the art gallery exhibit, next to the projection of ocean waves",
      width: 1920,
      height: 1400,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Rendered Exhibition Information Wall",
      description: "",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 6,
    },
    obsessive_seas_render_06: {
      name: "obsessive_seas_render_06",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_render_06.png",
      alt: "A close rendering of the abstract object casting shadows in the back corner of the Obsessive Seas art gallery",
      width: 1920,
      height: 1400,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Planned Positioning and Lighting for Jacques Jarrige’s Work",
      // description: "A close 3D rendering of the abstract art piece casting shadows on the back walls of the gallery.  This use of lighting was give more presence to a piece of such a small stature",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 7,
    },
    obsessive_seas_render_02: {
      name: "obsessive_seas_render_02",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_render_02.png",
      alt: "A more tightly focused 3D rendering of one of the two groups of framed artworks on the wall across from each other in the Obsessive Seas art gallery",
      width: 1920,
      height: 1400,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Positioning of Barbara Ellmann’s Work in Digital Gallery",
      // description: "A close-up rendered image of the gallery interior highlighting one of the framed art pieces. Scale and measurements were important at every stage of the project, so renders like these are 1:1 with the real-world gallery space.",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 8,
    },
    obsessive_seas_measurements_01: {
      name: "obsessive_seas_measurements_01",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_measurements_01.png",
      alt: "A more tightly focused 3D rendering of one of the two groups of framed artworks on the wall across from each other in the Obsessive Seas art gallery, with annotated measurements",
      width: 1920,
      height: 1401,
      title: "Annotated Render of Barbara Ellmann’s Work in Digital Gallery",
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 9,
    },
    obsessive_seas_release: {
      name: "obsessive_seas_release",
      src: "/assets/images/explorations/3d_design/obsessive_seas/obsessive_seas_release.png",
      alt: "A screenshot of a website with a press release for the Obsessive Seas art exhibit",
      width: 1920,
      height: 3939,
      disciplines: ["3D Design", "UX Design"],
      tools: ["Blender", "Photoshop"],
      title: "Press Release, Serendipity Art Space Website (Translated)",
      // description: "The Obsessive Seas exhibit’s press release, as seen on the gallery’s website. Translated from Mandarin to English.",
      //   study: "",
      group: "obsessive_seas",
      project: `Navigating <i>Obsessive\u00A0Seas</i>`,
      projectStr: "Navigating Obsessive Seas",
      index: 10,
      zoom: true,
      aspect: "16/9",
    },
    // virtual_pangea_temp: {
    //   name: "virtual_pangea_temp",
    //   src: "/assets/images/explorations/frontend/virtual_pangea_temp.mp4",
    //   //   group: "",
    //   //   index: 0,
    // },

    virtual_pangea_temp_thumbnail: {
      name: "virtual_pangea_temp_thumbnail",
      src: "/assets/images/explorations/frontend/virtual_pangea_temp/virtual_pangea_temp_thumbnail.png",
      alt: "A thumbnail for a screen-recorded video showing the Virtual Pangea website in action",
      width: 861,
      height: 861,
      group: "virtual_pangea_temp",
      thumbnail: true,
      hidden: true,
      project: "Virtual Pangea Website",
    },
    virtual_pangea_temp_video: {
      name: "virtual_pangea_temp_video",
      src: "/assets/images/explorations/frontend/virtual_pangea_temp/virtual_pangea_temp_video.mp4",
      alt: "A screen-recorded video showing the Virtual Pangea website in action",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS"],
      // title: "Virtual Pangea Website",
      description: [
        "As Virtual Pangea’s UX Engineer, I was tasked with salvaging our outsourced website.  The product we were delivered was subpar, and left us well behind schedule.",
        "I implemented necessities like navigation, and responsivity. Content blocks and features that were missing or broken, were rebuilt.",
        "To the eye of the user, I added animations and passed over styling and typesetting, making a far more digestible experience. Under the hood, I added SEO, and optimized performance.",
      ],
      group: "virtual_pangea_temp",
      project: "Virtual Pangea Website",
    },

    crypic_pixel_video_01: {
      name: "crypic_pixel_video_01",
      src: "/assets/images/explorations/frontend/cryptic_pixel/crypic_pixel_video_01.mp4",
      alt: "A screen-recorded video showing the Cryptic Pixel website in action",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Website Demo",
      description: [
        "As the UX Engineer at Virtual Pangea, I developed the website for our sister company, Cryptic Pixel.  It’s a fun, vibrant online identity, with a look like no other.",
        "My front-end skills were constantly pushed by the unique, tactile UI elements. In parallel, my abilities in UX were honed through an open dialogue between myself and our talented designer.",
        "I actively influenced, and extrapolated from mockups through a mutual exchange of feedback and ideas. Allowing me to elevate the look and feel of the interactions wherever possible, rather than simply taking the designs from A to B.",
        "Fully responsive, parity between major browsers, and accessible to all users.",
      ],
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   group: "",
      index: 0,
    },
    cryptic_pixel_01_home: {
      name: "cryptic_pixel_01_home",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_01_home.png",
      alt: "A screenshot of the Cryptic Pixel website homepage",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Landing Page",
      description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_02_games: {
      name: "cryptic_pixel_02_games",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_02_games.png",
      alt: "A screenshot of the Cryptic Pixel website games section, with two game cartidges displayed",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Game Selection",
      // description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_03_events: {
      name: "cryptic_pixel_03_events",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_03_events.png",
      alt: "A screenshot of the Cryptic Pixel website events section, with information about past and upcoming competitions",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Events",
      // description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_04_gallery: {
      name: "cryptic_pixel_04_gallery",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_04_gallery.png",
      alt: "A screenshot of the Cryptic Pixel website gallery section, with an interactive carousel of NFT artworks pulled from the OpenSea marketplace",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Gallery",
      // description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_05_youtube: {
      name: "cryptic_pixel_05_youtube",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_05_youtube.png",
      alt: "A screenshot of the Cryptic Pixel website YouTube section, with an interactive carousel of videos from their youtube channel with a main video above them, and a row of thumbnails below",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "YouTube Carousel",
      // description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_06_contact: {
      name: "cryptic_pixel_06_contact",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_06_contact.png",
      alt: "A screenshot of the Cryptic Pixel website contact section, with a form for users to submit their information",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Contact",
      // description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_07_gameplay: {
      name: "cryptic_pixel_07_gameplay",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_07_gameplay.png",
      alt: "A screenshot of a secondary page in the Cryptic Pixel website, showing a carousel of screenshots with screenshots of gameplay",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Secondary Page, Game Info",
      description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    cryptic_pixel_08_bosses: {
      name: "cryptic_pixel_08_bosses",
      src: "/assets/images/explorations/frontend/cryptic_pixel/cryptic_pixel_08_bosses.png",
      alt: "A screenshot of a secondary page in the Cryptic Pixel website, showing a carousel of 8-bit bosses from their flagship game, as well as a leaderboard underneath",
      width: 1920,
      height: 1080,
      disciplines: ["UX Engineering", "Frontend Development"],
      tools: ["HTML/CSS/JS", ".NET Environment"],
      title: "Secondary Page, Bosses and Leaderboard",
      // description: "",
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      //   index: 0,
    },
    crypic_pixel_thumbnail: {
      name: "crypic_pixel_thumbnail",
      src: "/assets/images/explorations/frontend/cryptic_pixel/crypic_pixel_thumbnail.png",
      alt: "A thumbnail for a screen-recorded video showing the Cryptic Pixel website in action",
      width: 1024,
      height: 1024,
      group: "cryptic_pixel",
      project: "Cryptic Pixel",
      thumbnail: true,
      hidden: true,
    },
    discog_calc_video_01_example_brockhampton: {
      name: "discog_calc_video_01_example_brockhampton",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_video_01_example_brockhampton.mp4",
      alt: "A screen-recorded video showing the Discogs-API powered website in action, searching for BROCKHAMPTON and returning their discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "Demo 1",
      description: [
        `“How much would it cost to own an artist’s entire physical discography?”  To music collectors like myself, this can be a common question, which is why I built the “Discography Calculator”.`,
        `This handy tool was designed and built with enthusiasts and record store owners in mind.  It aims to help make purchasing plans, set goals for your collection, or just satisfy curiosity.`,
        `Powered by Node.js, and the Discogs API, the site uses live data straight from marketplace listings.  Enter the name of any artist, and receive a grand sum for each of their physical releases, the cost per album, and their percentage of the total cost.`,
      ],
      group: "discog_calc",
      project: "Discography Calculator",
    },
    discog_calc_video_02_example_simz: {
      name: "discog_calc_video_02_example_simz",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_video_02_example_simz.mp4",
      alt: "A screen-recorded video showing the Discogs-API powered website in action, searching for Little Simz and returning their discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "Demo 2",
      description: "",
      group: "discog_calc",
      project: "Discography Calculator",
      //   index: 0,
    },
    discog_calc_video_03_example_aphex: {
      name: "discog_calc_video_03_example_aphex",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_video_03_example_aphex.mp4",
      alt: "A screen-recorded video showing the Discogs-API powered website in action, searching for Aphex Twin and returning their discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "Demo 3",
      description: "",
      group: "discog_calc",
      project: "Discography Calculator",
      //   group: "",
      //   index: 0,
    },
    discog_calc_01_home: {
      name: "discog_calc_01_home",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_01_home.png",
      alt: "A screenshot of the homepage of a Discogs-API powered website that allows users to search for artists and view the total cost of their discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "Homepage",
      description: "",
      group: "discog_calc",
      project: "Discography Calculator",
      //   index: 0,
    },
    discog_calc_02_searched: {
      name: "discog_calc_02_searched",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_02_searched.png",
      alt: "A screenshot of the results page of the same Discogs-API powered website, showing a returned search result; the artist, their bio, and the total cost of their discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "Searched",
      description: "",
      group: "discog_calc",
      project: "Discography Calculator",
      //   index: 0,
    },
    discog_calc_03_expanded: {
      name: "discog_calc_03_expanded",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_03_expanded.png",
      alt: "A breakdown of the artists discography, the cost of each item, and the percentage amount that release makes up of the total cost of the discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "More Info",
      description: "",
      group: "discog_calc",
      project: "Discography Calculator",
      //   index: 0,
    },
    discog_calc_04_scrolled: {
      name: "discog_calc_04_scrolled",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_04_scrolled.png",
      alt: "A full view of the album screen of the same Discogs-API powered website, showing the album cover, and a link to the discogs page for it",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      title: "Scrolled Down",
      description: "",
      group: "discog_calc",
      project: "Discography Calculator",
      //   index: 0,
    },
    discog_calc_thumbnail_01: {
      name: "discog_calc_thumbnail_01",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_thumbnail_01.png",
      alt: "A thumbnail image of a screen-recorded video showing the Discogs-API powered website in action, searching for BROCKHAMPTON and returning their discography",
      width: 658,
      height: 658,
      group: "discog_calc",
      project: "Discography Calculator",
      thumbnail: true,
      hidden: true,
    },
    discog_calc_thumbnail_02: {
      name: "discog_calc_thumbnail_02",
      src: "/assets/images/explorations/frontend/discog_calc/discog_calc_thumbnail_02.png",
      alt: "A thumbnail image of a screen-recorded video showing the Discogs-API powered website in action, searching for BROCKHAMPTON and returning their discography",
      width: 1920,
      height: 1080,
      disciplines: ["Frontend Development", "UX Design", "UI Design"],
      tools: ["HTML/CSS/JS", "API", "Adobe XD"],
      group: "discog_calc",
      project: "Discography Calculator",
      hidden: true,
    },

    killer_mike_motion_graphic_thumbnail_01: {
      name: "killer_mike_motion_graphic_thumbnail_01",
      src: "/assets/images/explorations/motion_graphics/killer_mike/killer_mike_motion_graphic_thumbnail_01.png",
      alt: "Thumbnail image for a motion graphic for a soundbite from Killer Mike on the Stephen Colbert show",
      width: 1920,
      height: 1080,
      group: "killer_mike",
      hidden: true,
      thumbnail: true,
      project: "Kinetic Typography",
    },
    killer_mike_motion_graphic_thumbnail_02: {
      name: "killer_mike_motion_graphic_thumbnail_02",
      src: "/assets/images/explorations/motion_graphics/killer_mike/killer_mike_motion_graphic_thumbnail_02.png",
      alt: "Thumbnail image for a motion graphic for a soundbite from Killer Mike on the Stephen Colbert show",
      width: 1920,
      height: 1080,
      group: "killer_mike",
      hidden: true,
      project: "Kinetic Typography",
    },
    killer_mike_motion_graphic_video: {
      name: "killer_mike_motion_graphic_video",
      src: "/assets/images/explorations/motion_graphics/killer_mike/killer_mike_motion_graphic_video.mp4",
      alt: "A kinetic typography motion graphic for a soundbite from Killer Mike on the Stephen Colbert show",
      width: 1920,
      height: 1080,
      disciplines: ["Motion Graphics"],
      tools: ["After Effects", "Adobe Illustrator", "Photoshop"],
      description: [
        "I set out to create a kinetic typography animation that enhanced an audio clip without overshadowing its core message.",
        "I refined the visual style through iteration, storyboarding, and embracing feedback. Each shot features engaging graphics that complement the tone and words spoken.",
        "This project pushed my skills in After Effects at every turn.  Thankfully, my passion for the subject and medium kept me motivated throughout.",
      ],
      group: "killer_mike",
      project: "Kinetic Typography",
    },
    makeright_presentation_thumbnail: {
      name: "makeright_presentation_thumbnail",
      src: "/assets/images/explorations/motion_graphics/makeright_presentation/makeright_presentation_thumbnail.png",
      alt: "Thumbnail for a narrated, and scored motion graphic presentation for a fictional company, MakeRight, created for my undergrad thesis",
      width: 1920,
      height: 1080,
      group: "makeright_presentation",
      hidden: true,
      thumbnail: true,
      project: "MakeRight Presentation",
    },
    makeright_presentation_video: {
      name: "makeright_presentation_video",
      src: "/assets/images/explorations/motion_graphics/makeright_presentation/makeright_presentation_video.mp4",
      alt: "A narrated, and scored motion graphic presentation for a fictional company, MakeRight, created for my undergrad thesis",
      width: 1920,
      height: 1080,
      disciplines: ["Motion Graphics"],
      tools: ["After Effects", "Ableton Live", "Adobe Illustrator", "Photoshop"],
      title: "MakeRight Presentation",
      description: [
        "For my undergraduate capstone, I designed a service that connects owners of 3D printers, to customers in need. ",
        "To help convey the concept and how it works, I created this short presentation video.  Motion graphics, a concise script, and informative visuals turn this multi-faceted project, into something fun and digestible.",
        "Check out my MakeRight case study for an in-depth look at the design process, and final product.",
      ],
      study: "makeright",
      group: "makeright_presentation",
      project: "MakeRight Presentation",
    },

    anc_solutions_preroll_animation: {
      name: "anc_solutions_preroll_animation",
      src: "/assets/images/explorations/motion_graphics/anc_solutions_preroll_animation/anc_solutions_preroll_animation_video.mp4",
      alt: "A short motion graphic animation for ANC Solutions which I was commissioned to create for their website homepage.",
      width: 2000,
      height: 2250,
      disciplines: ["Motion Graphics"],
      tools: ["After Effects", "Adobe Illustrator", "Adobe XD", "Photoshop"],
      // title: "ANC Hero Animation",
      description: [
        <>Working alongside design studio <DLink href="https://www.bettergood.agency/" target="_blank">Better Good</DLink>, I created this short, looping motion graphic for pre-roll manufacturer ANC Solutions.</>,
        <>
          The goal was to give users an exciting introduction to ANC’s new online identity, and their company ethos, <i>“precision in every roll</i>.<i>”</i>
        </>,
        "Created with a tight focus on aesthetic consistency with their site, and identity.",
      ],
      group: "anc_hero",
      project: "ANC Solutions Hero Animation",
    },

    anc_solutions_preroll_animation_screenshot: {
      name: "animation_in_situ",
      src: "/assets/images/explorations/motion_graphics/anc_solutions_preroll_animation/animation_in_situ.png",
      disciplines: ["Motion Graphics"],
      tools: ["After Effects", "Adobe Illustrator", "Adobe XD", "Photoshop"],
      alt: "",
      width: 1899,
      height: 1080,
      index:2,
      group: "anc_hero",
      project: "ANC Solutions Hero Animation",
    },

    anc_solutions_preroll_animation_thumbnail: {
      name: "thumb1",
      src: "/assets/images/explorations/motion_graphics/anc_solutions_preroll_animation/thumb1.png",
      alt: "Thumbnail for the hero animation I created for ANC Solutions.",
      width: 2000,
      height: 2250,
      group: "anc_hero",
      project: "ANC Solutions Hero Animation",
      hidden: true,
      thumbnail: true,
    },

    // thumb1: {
    //   name: "thumb1",
    //   src: "./assets/images/explorations/motion_graphics/anc_solutions_preroll_animation/thumb1.png",
    //   alt: "",
    //   width: 2000,
    //   height: 2250,
    // },
    // thumb2: {
    //   name: "thumb2",
    //   src: "./assets/images/explorations/motion_graphics/anc_solutions_preroll_animation/thumb2.png",
    //   alt: "",
    //   width: 2000,
    //   height: 2250,
    // },
    // anc_solutions_preroll_animation_video: {
    //   name: "anc_solutions_preroll_animation_video",
    //   src: "./assets/images/explorations/motion_graphics/anc_solutions_preroll_animation/anc_solutions_preroll_animation_video.mp4",
    //   alt: "",
    //   width: 2000,
    //   height: 2250,
    // },

    // makeright_presentation_video: {
    //   name: "makeright_presentation_video",
    //   src: "/assets/images/explorations/motion_graphics/makeright_presentation_video.mp4",
    // },

    // prepr_project_leadership: {
    //   name: "prepr_project_leadership",
    //   src: "/assets/images/explorations/motion_graphics/prepr_project_leadership.mp4",
    //   alt: "A short, peppy motion graphic for a company called Prepr, explaining their outlook and advice on project leadership",
    //   width: 1920,
    //   height: 1080,
    //   disciplines: ["Motion Graphics"],
    //   tools: ["After Effects", "Adobe Illustrator", "Photoshop"],
    //   title: "Prepr Project Leadership Overview",
    //   description: "A brief, upbeat overview of Prepr’s project leadership philosophy, created during my time as the company’s UX/UI Designer.",
    //   // study: "",
    //   //   group: "",
    //   //   index: 0,
    // },
    // abandoned_railroad: {
    //   name: "abandoned_railroad",
    //   src: "/assets/images/explorations/photography/abandoned_railroad.jpg",
    //   alt: "A photograph of an old abandoned building",
    //   width: 5027,
    //   height: 3351,
    //   title: "Railroad",
    //   disciplines: ["Photography"],
    //   group: "",
    //   index: 0,
    // },
    boat_eli_nate: {
      name: "boat_eli_nate",
      src: "/assets/images/explorations/photography/boat_eli_nate.jpg",
      alt: "A close up photograph of two men in a boat on a lake",
      width: 5184,
      height: 3456,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      title: "Gone Fishin’",
      // study: "",
      //   group: "",
      //   index: 0,
    },
    dad_and_the_heli: {
      name: "dad_and_the_heli",
      src: "/assets/images/explorations/photography/dad_and_the_heli.jpg",
      alt: "a close up photograph of a man standing next to a helicopter atop a mountain",
      width: 3835,
      height: 2521,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      title: "Heli",
      // study: "",
      //   group: "",
      //   index: 0,
    },
    // eclipse: {
    //   name: "eclipse",
    //   src: "/assets/images/explorations/photography/eclipse.JPG",
    //   alt: "A photograph of the sun during an eclipse",
    //   width: 5184,
    //   height: 3456,
    //   disciplines: ["Photography"],
    //   tools: ["Lightroom"],
    //   title: "Eclipse",

    //   // study: "",
    //   //   group: "",
    //   //   index: 0,
    // },
    foggy_boat: {
      name: "foggy_boat",
      src: "/assets/images/explorations/photography/foggy_boat.jpg",
      alt: "A photograph of a boat in a bay with a dense fog in the air",
      width: 3456,
      height: 5184,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      title: "Boat",
      // study: "",
      //   group: "",
      //   index: 0,
    },
    hamilton: {
      name: "hamilton",
      src: "/assets/images/explorations/photography/hamilton.jpg",
      alt: "A photograph of a metal sign reading ‘Canada’ taken through a glass prism with an array of refractions scattered across the image",
      width: 5760,
      height: 3840,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      title: "Hamilton",
      // study: "",
      //   group: "",
      //   index: 0,
    },
    ju: {
      name: "ju",
      src: "/assets/images/explorations/photography/ju.jpg",
      alt: "A man in a yellow shirt looking down a hill",
      width: 5472,
      height: 3648,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      study: "",
      title: "Hill",
      //   group: "",
      //   index: 0,
    },
    lush_waterfall: {
      name: "lush_waterfall",
      src: "/assets/images/explorations/photography/lush_waterfall.jpg",
      alt: "A vibrant, luch photograph of a small waterfall",
      width: 3456,
      height: 5184,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      study: "",
      title: "Lush",
      //   group: "",
      //   index: 0,
    },
    mountain: {
      name: "mountain",
      src: "/assets/images/explorations/photography/mountain.jpg",
      alt: "A panoramic photograph of a mountain",
      width: 9877,
      height: 6784,
      disciplines: ["Photography"],
      tools: ["Lightroom", "Photoshop"],
      study: "",
      title: "Sunshine",
      //   group: "",
      //   index: 0,
    },
    prism: {
      name: "prism",
      src: "/assets/images/explorations/photography/prism.jpg",
      alt: "A trippy, refracted, colorful image of a tree taken through a glass prism",
      width: 3840,
      height: 5760,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      study: "",
      title: "Fall",
      //   group: "",
      //   index: 0,
    },
    nathan_and_ellie_01: {
      name: "nathan_and_ellie_01",
      src: "/assets/images/explorations/photography/nathan_and_ellie/nathan_and_ellie_01.jpg",
      alt: "A man and a woman in the water at a beach",
      width: 5184,
      height: 3456,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      group: "nathan_and_ellie",
      project: "Beach",
      //   index: 0,
    },
    nathan_and_ellie_02: {
      name: "nathan_and_ellie_02",
      src: "/assets/images/explorations/photography/nathan_and_ellie/nathan_and_ellie_02.jpg",
      alt: "A man and a woman on their way out of the water at a beach",
      width: 5184,
      height: 3456,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      group: "nathan_and_ellie",
      project: "Beach",
      //   index: 0,
    },
    nathan_and_ellie_03: {
      name: "nathan_and_ellie_03",
      src: "/assets/images/explorations/photography/nathan_and_ellie/nathan_and_ellie_03.jpg",
      alt: "A man and a woman emerging from the water on a beach",
      width: 5184,
      height: 3456,
      disciplines: ["Photography"],
      tools: ["Lightroom"],
      group: "nathan_and_ellie",
      project: "Beach",
      //   index: 0,
    },

    koalako_final_01: {
      name: "delivery_activities",
      src: "/assets/images/koalako/solution/solution_mockup_activities.png",
      alt: "A screenshot of the KoalaKo activities page, showing a list of activities that parents can do with their child. Each activity has a title, description, tags, an icon to favorite, and can be tapped to view more information",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Activities",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
      description: [
        "KoalaKo assists parents in discovering fun, and unique games for their kids.  Leading a team of 4 designers, our goal was to find a digital-age solution for fostering creativity.",
        "Parents can explore numerous age-appropriate activities, and locations for play.  Choosing to track their child’s creative growth enables tailored activity suggestions.  These diversify their play experience, and help build their toolkit of creative expression.",
        "View my KoalaKo case study for a deeper understanding of the design, and final product!",
      ],
    },
    koalako_final_02: {
      name: "delivery_statistics",
      src: "/assets/images/koalako/submission/submission_screen_10_statistics.png",
      alt: "A screenshot of the KoalaKo app showing the statistics screen.  This screen shows the number of activities that have been completed, the types of creative play within those activities such as physically active, or musical, the structure of the activities such as structured or unstructured, and filters for the timeframe of this data.",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Statistics",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
    },
    koalako_final_03: {
      name: "delivery_activity_listing",
      src: "/assets/images/koalako/submission/submission_screen_6_activity_listing.png",
      alt: "A screenshot of the KoalaKo game listing page, showing a listing of a game that has been selected from the activities screen. The listing shows the title, description, tags, an icon to favorite the game, user reviews, and a suggested local spot to play",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Activity Listing",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
    },
    koalako_final_04: {
      name: "delivery_map",
      src: "/assets/images/koalako/submission/submission_screen_4_location_browse.png",
      alt: "A closely cropped screenshot of the KoalaKo app which shows the ability to search your local area for child friendly play areas",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Browse Locations",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
    },
    koalako_final_05: {
      name: "delivery_trophies",
      src: "/assets/images/koalako/submission/submission_screen_8_trophy_case.png",
      alt: "A screenshot of the KoalaKo app showing the trophies screen. This screen is a collection of awards and achievements you can earn in various ways while using the app and completing activities.",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Trophy Case",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
    },
    koalako_final_06: {
      name: "delivery_post_activity",
      src: "/assets/images/koalako/submission/submission_screen_7_post_activity.png",
      alt: "A screenshot of the KoalaKo app where, after completing an activity, the user can post a review of the activity, and provide feedback, or continue on with another activity.",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Post-Activity",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
    },
    koalako_final_07: {
      name: "delivery_profile_child",
      src: "/assets/images/koalako/submission/submission_screen_9_child_profile.png",
      alt: "A screenshot of the KoalaKo app showing the profile of your child.  This profile contains no personal details about your child, except for their name which isnt necessary to provide.  The profile contains a list of activities that the child has completed, and their favourite activities.",
      width: 1125,
      height: 2436,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Child Profile",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
    },
    koalako_thumbnail: {
      name: "koalako_thumbnail",
      src: "/assets/images/explorations/ui_ux/koalako/koalako_thumbnail.png",
      alt: "Thumbnail graphic for the gallery listing of the KoalaKo project",
      width: 1392,
      height: 1392,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD", "Mural"],
      title: "Child Profile",
      study: "koalako",
      group: "koalako_delivery",
      project: "KoalaKo",
      hidden: true,
      thumbnail: true,
    },

    customer_screen_06: {
      name: "customer_screen_06",
      src: "/assets/images/explorations/ui_ux/makeright/customer_screen_06.png",
      alt: "Storefront page for MakeRight, where customers can browse through available items.",
      width: 3840,
      height: 7016,
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      title: "Customer: Storefront",
      description: [
        "For my undergrad capstone, I developed a gig economy-based platform to connect 3D printer owners with customers in need. ",
        "MakeRight dismantles the steep barriers of cost and technical knowledge that stand in the way of mass adoption.  It enables printer owners to monetize their skills, while giving customers easy access to a previously unreachable technology. ",
        "Check out my MakeRight case study for an in-depth look at the design process, and final product.",
      ],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },
    customer_screen_07: {
      name: "customer_screen_07",
      src: "/assets/images/explorations/ui_ux/makeright/customer_screen_07.png",
      alt: "Item listing page for MakeRight, where customers can view detailed information about the selected item, including an interactive 3D model, images of past prints, and comments from other customers.",
      width: 3840,
      height: 5030,
      title: "Customer: Store Item listing",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },
    customer_screen_08: {
      name: "customer_screen_08",
      src: "/assets/images/explorations/ui_ux/makeright/customer_screen_08.png",
      alt: "Checkout page for MakeRight, where customers can customize their order and finalize their purchase.",
      width: 3840,
      height: 4042,
      title: "Customer: Checkout",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },
    customer_screen_13: {
      name: "customer_screen_13",
      src: "/assets/images/explorations/ui_ux/makeright/customer_screen_13.png",
      alt: "Expanded Order Ticket page for MakeRight, where customers can manage their order details, communicate with their Maker, and request changes.",
      width: 3840,
      height: 4504,
      title: "Customer: Order ticket (accepted/vetting)",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },
    maker_screen_09_no_tutorial: {
      name: "maker_screen_09_no_tutorial",
      src: "/assets/images/explorations/ui_ux/makeright/maker_screen_09_no_tutorial.png",
      alt: "A screen showing a list of past and present job orders for makers, with optional tutorials disabled.",
      width: 3840,
      height: 2160,
      // title: "Maker: Orders (awaiting acceptance, tutorials off)",
      title: "Maker: Orders (awaiting acceptance)",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
    },
    // maker_screen_09_tutorial: {
    //   name: "maker_screen_09_tutorial",
    //   src: "/assets/images/explorations/ui_ux/makeright/maker_screen_09_tutorial.png",
    //   alt: "A screen showing a list of past and present job orders for makers, with optional tutorials enabled.",
    //   width: 3840,
    //   height: 2160,
    //   title: "Maker: Orders (awaiting acceptance, tutorials on)",
    //   disciplines: ["UX Design", "UI Design", "User Research"],
    //   tools: ["Adobe XD"],
    //   study: "makeright",
    //   group: "makeright_final",
    // project: "MakeRight",
    // },
    maker_screen_10: {
      name: "maker_screen_10",
      src: "/assets/images/explorations/ui_ux/makeright/maker_screen_10.png",
      alt: "A screen providing the details of a particular job order, with options to accept or reject it.",
      width: 3840,
      height: 6600,
      // title: "Maker: Order details (tutorials off)",
      title: "Maker: Order details",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },
    maker_screen_12_no_tutorial: {
      name: "maker_screen_12_no_tutorial",
      src: "/assets/images/explorations/ui_ux/makeright/maker_screen_12_no_tutorial.png",
      alt: "A screen for makers to download and vet a file to ensure it can be printed, with optional tutorials enabled.",
      width: 3840,
      height: 5354,
      // title: "Maker: File vetting (tutorials off)",
      title: "Maker: File vetting",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },
    // maker_screen_12_tutorial: {
    //   name: "maker_screen_12_tutorial",
    //   src: "/assets/images/explorations/ui_ux/makeright/maker_screen_12_tutorial.png",
    //   alt: "A screen for makers to download and vet a file to ensure it can be printed, with optional tutorials disabled.",
    //   width: 3840,
    //   height: 4518,
    //   // title: "Maker: File vetting (tutorials on)",
    //   title: "Maker: File vetting",
    //   disciplines: ["UX Design", "UI Design", "User Research"],
    //   tools: ["Adobe XD"],
    //   study: "makeright",
    //   group: "makeright_final",
    // project: "MakeRight",
    // },
    maker_screen_14: {
      name: "maker_screen_14",
      src: "/assets/images/explorations/ui_ux/makeright/maker_screen_14.png",
      alt: "A screen for makers to submit 4 images of the printed object for customer verification.",
      width: 3840,
      height: 4538,
      title: "Maker: Print verification",
      disciplines: ["UX Design", "UI Design", "User Research"],
      tools: ["Adobe XD"],
      study: "makeright",
      group: "makeright_final",
      project: "MakeRight",
      zoom: true,
      aspect: "16/9",
    },

    makeright_thumbnail_04: {
      name: "makeright_thumbnail_04",
      src: "/assets/images/explorations/ui_ux/makeright/makeright_thumbnail_04.png",
      alt: "A thumbnail for the MakeRight case study",
      width: 857,
      height: 857,
      group: "makeright_final",
      hidden: true,
      thumbnail: true,
      project: "MakeRight",
    },
    // TODO: uncomment once we’re compressing
    pano_01_house: {
      name: "pano_01_house",
      src: "/assets/images/explorations/photography/pano_01_house.jpg",
      alt: "A colorful panoramic image of a farmhouse in the countryside",
      width: 15160,
      height: 6781,
      disciplines: ["Photography"],
      tools: ["Photoshop", "Lightroom"],
      // group: "montreal_panos",
      // project: "Farmhouse",
      title: "Farmhouse",
    },
    pano_02_hill: {
      name: "pano_02_hill",
      src: "/assets/images/explorations/photography/pano_02_hill.jpg",
      alt: "A colorful panoramic image of a treeline on top of a mountain",
      width: 12806,
      height: 4275,
      disciplines: ["Photography"],
      tools: ["Photoshop", "Lightroom"],
      // group: "montreal_panos",
      // project: "Hill",
      title: "Hill",
    },
    pano_03_neighborhood: {
      name: "pano_03_neighborhood",
      src: "/assets/images/explorations/photography/pano_03_neighborhood.jpg",
      alt: "A colorful panoramic image of a neighborhood taken from the top of a mountain",
      width: 6526,
      height: 3834,
      disciplines: ["Photography"],
      tools: ["Photoshop", "Lightroom"],
      // group: "montreal_panos",
      // project: "Neighborhood",
      title: "Neighborhood",
    },

    // pano_01_house: {
    //   name: "pano_01_house",
    //   src: "/assets/images/explorations/photography/pano_01_house.jpg",
    //   alt: "A colorful panoramic image of a farm house in the countryside",
    //   width: 15160,
    //   height: 6781,
    //         disciplines: ["Photography"],
    //   tools: ["Photoshop","Lightroom"],
    //   group: "montreal_panos",
    //   project: "Mount Royal",
    // },
    // pano_02_hill: {
    //   name: "pano_02_hill",
    //   src: "/assets/images/explorations/photography/pano_02_hill.jpg",
    //   alt: "A colorful panoramic image of a treeline on top of a mountain",
    //   width: 12806,
    //   height: 4275,
    //         disciplines: ["Photography"],
    //   tools: ["Photoshop","Lightroom"],
    //   group: "montreal_panos",
    //   project: "Mount Royal",
    // },
    // pano_03_neighborhood: {
    //   name: "pano_03_neighborhood",
    //   src: "/assets/images/explorations/photography/pano_03_neighborhood.jpg",
    //   alt: "A colorful panoramic image of a neighborhood taken from the top of a mountain",
    //   width: 6526,
    //   height: 3834,
    //         disciplines: ["Photography"],
    //   tools: ["Photoshop","Lightroom"],
    //   group: "montreal_panos",
    //   project: "Mount Royal",
    // },
  },

  "explorations"
);

const EXPLORATIONS_IMG_GROUPS = processGroups(EXPLORATIONS_IMGS);

const EXPLORATIONS_ORDER = {
  "UX Engineering": {
    projects: ["cryptic_pixel", "virtual_pangea_temp"],
  },
  "UX Design": {
    projects: ["makeright_final", "koalako_delivery", "discog_calc"],
  },

  "3D Design": {
    projects: [
      "obsessive_seas",
      "cinema",
      "nft_dome",
      "foundation_bottles",
      "made_shirts",
      "jabbar_and_co_products",
      "modern_room",
      // "headphones",
      "submarine",
      "to_alleyway",
      "appa",
      "finn",
      "pops",
    ],
  },
  "Motion Graphics": {
    projects: ["makeright_presentation", "killer_mike_motion_graphic"],
  },
  Photography: {
    projects: [
      "dad_and_the_heli",
      "boat_eli_nate",
      "nathan_and_ellie",
      // "abandoned_railroad",
      "foggy_boat",
      // "eclipse",
      "lush_waterfall",
      "hamilton",
      "mountain",
      "ju",
      "prism",
    ],
  },
};

export { EXPLORATIONS_IMGS, EXPLORATIONS_IMG_GROUPS };
export { EXPLORATIONS_ORDER };
